<template>
  <ion-page>
    <ion-content>
      <ion-title align="center">{{ $t('about.title') }}</ion-title>
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-col size-xl="8" size-lg="10" size-md="12" class="ion-no-margin ion-no-padding">
            <ion-row class="ion-justify-content-center">
              <ion-col size-lg="6" size-md="12" class="ion-no-margin ion-no-padding">
                <LgtronImage :src="'/assets/images/device.jpg'"></LgtronImage>
              </ion-col>
              <ion-col size-lg="6" size-md="12" class="text-block ion-no-margin ion-no-padding"
                       style="background: #deefff">
                <p>{{ $t('about.us') }}</p>
                <ul>
                  <li>
                    {{ $t('about.point-1') }}
                  </li>
                  <li>
                    {{ $t('about.topic-2') }}
                  </li>
                  <li>
                    {{ $t('about.topic-3') }}
                  </li>
                </ul>
                <p>
                  <i18n-t keypath="about.contactLink" tag="span" for="tos">
                    <a href="mailto:freeze-dried-food@lgtron.com" target="_blank">{{ $t('about.contactLinkTerm') }}</a>
                  </i18n-t>
                </p>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>
      <LgtronFooter></LgtronFooter>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import {IonPage, IonTitle, IonContent, IonGrid, IonRow, IonCol} from '@ionic/vue';
import {LgtronImage, LgtronFooter} from "@/components";

export default {
  name: 'About',
  components: {IonTitle, IonContent, IonPage, IonGrid, IonRow, IonCol, LgtronImage, LgtronFooter},
}
</script>

<style lang="scss" scoped>
.lgtron-image {
  height: 100%;
  min-width: 20rem;
  object-fit: cover;
}

</style>